import { SupportedLocale } from '@src/cnst/translations.cnst'
import { AppStoreIcon } from '@src/components/app-store-icon/AppStoreIcon.component'
import { FooterHeading } from '@src/components/footer-heading/FooterHeading.component'
import { FooterLinks } from '@src/components/footer-links/FooterLinks.component'
import { GooglePlayIcon } from '@src/components/google-play-icon/GooglePlayIcon.component'
import {
  LanguagePicker,
  LanguagePickerProps,
} from '@src/components/language-picker/LanguagePicker.component'
import { languageContext } from '@src/context/language.context'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { NavMenuFooterPlacements, useNavMenuFooter } from '@src/hooks/useNavMenuFooter'
import { ConsumerPageTypes, PageType, ShopPageTypes, usePageType } from '@src/hooks/usePageType'
import { useTranslation } from '@src/hooks/useTranslation'
import { selectGeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import { useContext } from 'react'
import { ClientOnly } from '../client-only/ClientOnly.component'
import { NcLink } from '../nc-link/NcLink.component'
import { RotatingUsp } from '../rotating-usp/RotatingUsp.component'
import styles from './Footer.module.scss'

interface FooterProps {
  theme?: 'grey' | 'purple'
  sitemap: LanguagePickerProps['sitemap']
}

const noFooterPageTypes = [PageType.Campaign, PageType.WebView]
const consumerFooterPageTypes = [...ConsumerPageTypes, ...ShopPageTypes]

const usps: Partial<Record<SupportedLocale, string[]>> = {
  [SupportedLocale.enUS]: [
    'Birth control.',
    '100% Hormone-free.',
    'Regulated & certified.',
    'Prescription-free.',
    'Tailored to you.',
    'Effective.',
  ],
  [SupportedLocale.svSE]: [
    'Preventivmedel.',
    '100% Hormonfri.',
    'Reglerad och certifierad.',
    'Receptfri.',
    'Skräddarsydd för dig.',
    'Effektiv.',
  ],
  [SupportedLocale.ptBR]: [
    'Controle de natalidade.',
    '100% Livre de hormônios.',
    'Regulamentado e certificado.',
    'Sem prescrição médica.',
    'Personalizado para você.',
    'Eficaz.',
  ],
  [SupportedLocale.esUS]: [
    'Método contracetivo.',
    '100% Libre de hormonas.',
    'Regulado y certificado.',
    'Sin necesidad de receta.',
    'Personalizado para ti.',
    'Efectivo.',
  ],
}

export function Footer({ theme = 'grey', sitemap }: FooterProps): ReactNode {
  const pageType = usePageType()
  const translate = useTranslation()
  const { locale, fallbackLocale } = useContext(languageContext)
  const country = useAppSelector(selectGeolocationCountry)

  const consumerAppFooter = useNavMenuFooter(NavMenuFooterPlacements.FooterApp, country)
  const consumerCompanyFooter = useNavMenuFooter(NavMenuFooterPlacements.FooterCompany, country)
  const consumerScienceResearchFooter = useNavMenuFooter(
    NavMenuFooterPlacements.FooterScienceResearch,
    country,
  )
  const consumerSupportFooter = useNavMenuFooter(NavMenuFooterPlacements.FooterSupport, country)

  // test 227
  const uspsList = usps[locale] || usps[fallbackLocale!] || []

  function getLoginUrl(locale: string): string {
    if (locale === 'sv-SE') {
      return `${process.env['APP_URL']}?lang=sv&webLogin=1`
    }
    if (locale === 'es-US') {
      return `${process.env['APP_URL']}?lang=es&webLogin=1`
    }
    if (locale === 'pt-BR') {
      return `${process.env['APP_URL']}?lang=pt&webLogin=1`
    }
    if (locale === 'en-CA') {
      return `${process.env['APP_URL']}?lang=en-CA&webLogin=1`
    }
    return `${process.env['APP_URL']}?webLogin=1`
  }

  const consumerFooterPageContent = (
    <>
      <div className={styles.Footer__app}>
        <FooterHeading theme={theme}>{translate('nav-app')}</FooterHeading>
        <FooterLinks theme={theme}>{consumerAppFooter}</FooterLinks>
      </div>
      <div className={styles.Footer__scienceResearch}>
        <FooterHeading theme={theme}>{translate('nav-science-research')}</FooterHeading>
        <FooterLinks theme={theme}>{consumerScienceResearchFooter}</FooterLinks>
      </div>
      <div className={styles.Footer__company}>
        <FooterHeading theme={theme}>{translate('nav-company')}</FooterHeading>
        <FooterLinks theme={theme}>{consumerCompanyFooter}</FooterLinks>
      </div>
      <div className={styles.Footer__support}>
        <FooterHeading theme={theme}>{translate('nav-nc-support')}</FooterHeading>
        <FooterLinks theme={theme}>{consumerSupportFooter}</FooterLinks>
      </div>
    </>
  )

  let isCartPage = false

  if (typeof window !== 'undefined') {
    const pathname = window.location.pathname
    isCartPage =
      pathname === '/shop/cart' || (pathname.startsWith('/shop/') && pathname !== '/shop/us')
  }

  return (
    <ClientOnly>
      {!noFooterPageTypes.includes(pageType) && (
        <footer className={styles.Footer} uid="site-footer">
          <div
            className={classNames(styles.Footer__widgets, {
              [styles.Footer__consumerWidgets]: consumerFooterPageTypes.includes(pageType),
            })}
          >
            {consumerFooterPageTypes.includes(pageType) && consumerFooterPageContent}

            <div className={styles.Footer__downloadApp}>
              <FooterHeading theme={theme}>{translate('nav-download-app')}</FooterHeading>
              <AppStoreIcon href="https://apps.apple.com/us/app/natural-cycles-birth-control/id765535549?ct=website_footer&fallback=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fnatural-cycles-fertility-app%2Fid765535549" />
              <GooglePlayIcon href="https://play.google.com/store/apps/details?id=com.naturalcycles.cordova&utm_campaign=website_footer" />
            </div>
          </div>

          <hr className={styles.Footer__hr} />
          <div className={styles.Footer__midSection}>
            <div className={styles.Footer__socialIcons}>
              <NcLink
                className={styles.Footer__socialLink}
                to="https://www.instagram.com/naturalcycles"
              >
                <img
                  loading="lazy"
                  alt="Instagram"
                  src="/icons/instagram.svg"
                  width={24}
                  height={24}
                />
              </NcLink>
              <NcLink to="https://www.facebook.com/NaturalCyclesNC">
                <img
                  loading="lazy"
                  alt="Facebook"
                  src="/icons/facebook.svg"
                  width={24}
                  height={24}
                />
              </NcLink>
              <NcLink to="https://twitter.com/NaturalCycles">
                <img loading="lazy" alt="Twitter" src="/icons/twitter.svg" width={24} height={24} />
              </NcLink>
              <NcLink to="https://www.linkedin.com/company/naturalcycles">
                <img
                  loading="lazy"
                  alt="LinkedIn"
                  src="/icons/linkedin.svg"
                  width={24}
                  height={24}
                />
              </NcLink>
              <NcLink to="https://www.tiktok.com/@naturalcycles">
                <img loading="lazy" alt="TikTok" src="/icons/tiktok.svg" width={24} height={24} />
              </NcLink>
              <NcLink to="https://www.youtube.com/c/naturalcycles">
                <img
                  loading="lazy"
                  alt="YouTube"
                  src="/icons/youtube.svg"
                  className={styles.Footer__youtubeIcon}
                />
              </NcLink>
            </div>

            <div className={styles.Footer__langPicker}>
              <LanguagePicker sitemap={sitemap} />
            </div>
          </div>

          {pageType === PageType.StandardHome && (
            <RotatingUsp backgroundColor="default" uspsList={uspsList} theme="footer" />
          )}

          <div className={isCartPage ? styles.Footer__bottomCart : styles.Footer__bottom}>
            <div className={styles.Footer__desktopContainer}>
              <ul className={styles.Footer__legalLinks}>
                <li>
                  <NcLink
                    uid="footer-nav-terms"
                    className={styles.Footer__legalLink}
                    to="/other/legal/terms"
                  >
                    {translate('nav-terms')}
                  </NcLink>
                </li>
                <li>
                  <NcLink
                    uid="footer-nav-login"
                    className={styles.Footer__legalLink}
                    to={getLoginUrl(locale)}
                  >
                    {translate('nav-login')}
                  </NcLink>
                </li>
                <li>
                  <NcLink
                    uid="footer-nav-privacy"
                    className={styles.Footer__legalLink}
                    to="/other/legal/privacy"
                  >
                    {translate('nav-privacy')}
                  </NcLink>
                </li>
                <li>
                  <NcLink
                    uid="footer-nav-cookies"
                    className={styles.Footer__legalLink}
                    to="/other/legal/cookies"
                  >
                    {translate('nav-cookies')}
                  </NcLink>
                </li>
                <li>
                  <NcLink
                    uid="footer-nav-privacy-choices"
                    className={styles.Footer__legalLink}
                    to="/other/legal/your-privacy-choices"
                  >
                    {translate('nav-privacy-choices')}
                    <img src="/icons/privacyoptions.svg" alt="privacy options" />
                  </NcLink>
                </li>
              </ul>

              <div className={styles.Footer__companyName}>
                {locale === 'sv-SE' ? 'NaturalCycles Nordic AB ©' : 'Natural Cycles USA Corp'}
              </div>
            </div>
            {pageType === PageType.StandardHome && (
              <div className={styles.Footer___trailingButtonSpace} />
            )}
          </div>
        </footer>
      )}
    </ClientOnly>
  )
}
