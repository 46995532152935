import { _stringEnumKey } from '@naturalcycles/js-lib'
import { SiteLocale, SupportedLocale } from '@src/cnst/translations.cnst'
import { MainMenuItemRenderer } from '@src/components/menu-item-renderer/MenuItemRenderer.component'
import { languageContext } from '@src/context/language.context'
import { filterMenuItems } from '@src/helpers/filterMenuItems'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { selectGeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import { DisplayType, MenuItem } from '@src/typings/navigation-menu.d'
import type { ReactNode } from 'react'
import { useContext } from 'react'
import styles from './DesktopMenu.module.scss'

export interface DesktopMenuProps {
  menu: {
    _allNavigationItemsLocales: {
      locale: SiteLocale
      value: MenuItem[]
    }[]
  }
}

export function DesktopMenu({ menu: { _allNavigationItemsLocales } }: DesktopMenuProps): ReactNode {
  const country = useAppSelector(selectGeolocationCountry)
  const { locale } = useContext(languageContext)

  const filteredMenuItems: MenuItem[] = filterMenuItems(
    _allNavigationItemsLocales,
    SiteLocale[_stringEnumKey(SupportedLocale, locale)],
    country,
    DisplayType.Desktop,
  )
  const mainMenuItems = filteredMenuItems.filter(
    item => item.componentType.__typename !== 'MinorMenuRecord',
  )

  return (
    <ul uid="site-desktop-menu" className={styles.DesktopMenu}>
      {mainMenuItems.map(item => (
        <li key={item.id} className={styles.BurgerMenu__item}>
          <MainMenuItemRenderer item={item} />
        </li>
      ))}
    </ul>
  )
}
