import { EU_COUNTRIES, OURA_SHIPPING_COUNTRIES } from '@naturalcycles/shared'
import { SiteLocale } from '@src/cnst/translations.cnst'
import { GeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import { DisplayType, MenuItem, Region } from '@src/typings/navigation-menu.d'

export function filterMenuItems(
  menuData: { locale: string; value: MenuItem[] }[],
  locale: SiteLocale,
  country: string,
  displayType: DisplayType,
): MenuItem[] {
  // The menu uses a static query component, which limits data flexibility.
  // To adapt it dynamically, we filter the data here based on the page's locale
  const localeData = menuData.find(item => item.locale === locale)

  return localeData!.value.filter(item => {
    let regionMatch = true

    if (item.region === Region.OuraSellingCountries) {
      regionMatch = OURA_SHIPPING_COUNTRIES.includes(country)
    } else if (item.region === Region.Usa) {
      regionMatch = country === GeolocationCountry.US
    } else if (item.region === Region.Europe) {
      regionMatch = EU_COUNTRIES.includes(country)
    }

    const displayMatch = !item.display || item.display === displayType

    return regionMatch && displayMatch
  })
}
