import { Button } from '@src/components/button/Button.component'
import { Breakpoints } from '@src/hooks/useBreakpoint'
import { useWindowDimensions } from '@src/hooks/useWindowDimensions'
import classNames from 'classnames'
import type { ReactNode, RefObject } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { MenuArticleModal } from '../menu-article-modal/MenuArticleModal.component'
import {
  MenuButton,
  MenuCategory,
  MenuCategoryItem,
} from '../menu-category-item/MenuCategoryItem.component'
import styles from './MenuLibrary.module.scss'

export interface MenuLibraryProps {
  label: string
  uid: string
  categories: MenuCategory[]
  button: MenuButton
  desktopHeading: string
  desktopContext: string
  desktopButton: {
    text: string
    url: string
  }
}

export function MenuLibrary({
  label,
  categories,
  button,
  desktopHeading,
  desktopContext,
  desktopButton,
  uid,
}: MenuLibraryProps): ReactNode {
  const OPEN_DURATION = 0.4 // seconds
  const CLOSE_DURATION = 0.3 // seconds
  const INITIAL_HEIGHT_MOBILE = 56
  const INITIAL_HEIGHT_DESKTOP = 0
  const PADDING_ON_CLOSE = 0
  const PADDING_ON_OPEN = 24

  const [isOpen, setIsOpen] = useState(false)
  const [isClosing, setIsClosing] = useState(false)
  const [shouldAnimate, setShouldAnimate] = useState(true)
  const [height, setHeight] = useState(INITIAL_HEIGHT_MOBILE)
  const [modalCategoryData, setModalCategoryData] = useState<MenuCategory | null>(null)
  const [selectedCategory, setSelectedCategory] = useState<MenuCategory>(categories[0]!)

  const { width } = useWindowDimensions()
  const isDesktop = width && width >= Breakpoints.bpLarge

  const categoryListRef = useRef<HTMLDivElement>(null)
  const categoryListContainerRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const toggleDropdown = useCallback(() => {
    setIsOpen(prevState => {
      const willOpen = !prevState

      updateHeight(willOpen)
      handleBodyScrollLock(willOpen)
      handleAnimationStates(willOpen)

      return willOpen
    })
  }, [isDesktop])

  const closeMenu = useCallback(() => {
    setIsOpen(false)

    if (isDesktop) {
      setIsClosing(true)
      setBodyScrollLock(false)
      setHeight(0)

      setTimeout(() => {
        setIsClosing(false)
      }, CLOSE_DURATION * 1000)

      buttonRef.current?.focus()
      return
    }

    setHeight(INITIAL_HEIGHT_MOBILE)
    setShouldAnimate(true)
    setIsClosing(true)

    setTimeout(() => {
      setIsClosing(false)
    }, CLOSE_DURATION * 1000)

    buttonRef.current?.focus()
  }, [isDesktop])

  useEffect(() => {
    if (!isOpen) return

    const ref = isDesktop ? categoryListContainerRef : categoryListRef
    setHeight(getInitialHeight(ref, true, !!isDesktop))

    if (isDesktop) {
      setBodyScrollLock(true)
    }

    return () => {
      if (isDesktop) {
        setBodyScrollLock(false)
      }
    }
  }, [isOpen, isDesktop])

  useEffect(() => {
    if (!isOpen) return

    function handleKeyDown(e: KeyboardEvent): void {
      if (e.key === 'Escape') {
        closeMenu()
      }
    }

    function handleClickOutside(e: MouseEvent): void {
      if (categoryListRef.current && !categoryListRef.current.contains(e.target as Node)) {
        closeMenu()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, closeMenu])

  function setBodyScrollLock(locked: boolean): void {
    document.body.style.overflow = locked ? 'hidden' : ''
  }

  function getInitialHeight(
    ref: RefObject<HTMLElement>,
    isOpen: boolean,
    isDesktop: boolean,
  ): number {
    if (ref.current) {
      return isOpen
        ? ref.current.scrollHeight
        : isDesktop
          ? INITIAL_HEIGHT_DESKTOP
          : INITIAL_HEIGHT_MOBILE
    }
    return isDesktop ? INITIAL_HEIGHT_DESKTOP : INITIAL_HEIGHT_MOBILE
  }

  function updateHeight(willOpen: boolean): void {
    const ref = isDesktop ? categoryListContainerRef : categoryListRef
    setHeight(getInitialHeight(ref, willOpen, !!isDesktop))
  }

  function handleBodyScrollLock(willOpen: boolean): void {
    if (isDesktop) {
      setBodyScrollLock(willOpen)
    }
  }

  function handleAnimationStates(willOpen: boolean): void {
    setShouldAnimate(true)
    if (!willOpen) {
      setIsClosing(true)
      setTimeout(() => setIsClosing(false), CLOSE_DURATION * 1000)
    }
  }

  const mobileAnimationStyles = {
    height: `${height}px`,
    overflow: 'hidden',
    transition: shouldAnimate ? `height ${isOpen ? OPEN_DURATION : CLOSE_DURATION}s ease` : 'none',
  }

  const desktopAnimationStyles = {
    height: `${height}px`,
    paddingTop: isClosing ? PADDING_ON_CLOSE : PADDING_ON_OPEN,
    paddingBottom: isClosing ? PADDING_ON_CLOSE : PADDING_ON_OPEN,
    overflow: 'hidden',
    transition: shouldAnimate
      ? `height ${isClosing ? CLOSE_DURATION : OPEN_DURATION}s ease, padding ${
          isClosing ? CLOSE_DURATION : OPEN_DURATION
        }s ease`
      : 'none',
  }

  return (
    <div
      ref={categoryListRef}
      className={classNames(styles.MenuLibrary, {
        [styles.MenuLibrary___open]: isOpen,
      })}
      style={!isDesktop ? mobileAnimationStyles : undefined}
    >
      <button
        ref={buttonRef}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls="mobile-menu-library-categories"
        onClick={toggleDropdown}
        className={styles.MenuLibrary__button}
        uid={uid}
      >
        <div className={styles.MenuLibrary__buttonContainer}>
          {label}
          <img
            className={styles.MenuLibrary__chevron}
            data-open={isOpen}
            src="/icons/chevron-icon.svg"
            aria-hidden="true"
            alt=""
          />
        </div>
      </button>

      {(isOpen || (isDesktop && isClosing)) && (
        <>
          <div
            ref={isDesktop ? categoryListContainerRef : undefined}
            className={styles.MenuLibrary__categoryList}
            style={isDesktop ? desktopAnimationStyles : undefined}
          >
            <div className={styles.MenuLibrary__desktopInfo}>
              <h2>{desktopHeading}</h2>
              <p>{desktopContext}</p>
              <div className={styles.MenuLibrary__desktopInfoButton} onClick={() => closeMenu()}>
                <Button size="small" href={desktopButton.url}>
                  {desktopButton.text}
                </Button>
              </div>
            </div>

            <ul
              id="mobile-menu-library-categories"
              aria-label={`${label} categories`}
              className={styles.MenuLibrary__categories}
            >
              {categories.map(categoryData => (
                <li key={categoryData.category}>
                  <MenuCategoryItem
                    categoryData={categoryData}
                    isDesktop={!!isDesktop}
                    onSelect={() => setSelectedCategory(categoryData)}
                    isSelected={selectedCategory === categoryData}
                    closeMenu={closeMenu}
                    onOpenModal={categoryData => setModalCategoryData(categoryData)}
                    modalCategoryData={modalCategoryData}
                  />
                </li>
              ))}
            </ul>

            {isDesktop && selectedCategory && (
              <MenuArticleModal
                isDesktop={isDesktop}
                categoryData={selectedCategory}
                modalId="desktop-articles-section"
                closeMenu={closeMenu}
                closeModal={() => setSelectedCategory(categories[0]!)}
              />
            )}
            <div className={styles.MenuLibrary__sectionButton} onClick={() => closeMenu()}>
              <Button theme="ghost" href={button.href}>
                {button.cta}
              </Button>
            </div>

            <button
              className={styles.MenuLibrary__closeButton}
              aria-label="close"
              onClick={toggleDropdown}
            >
              <img src="/icons/close-icon.svg" alt="Close" aria-hidden="true" />
            </button>
          </div>
          <div className={styles.MenuLibrary__overlay} />
        </>
      )}

      {modalCategoryData && !isDesktop && (
        <MenuArticleModal
          isDesktop={!!isDesktop}
          modalId={`modal-${modalCategoryData.category.replaceAll(/\s+/g, '-').toLowerCase()}`}
          categoryData={modalCategoryData}
          closeModal={() => setModalCategoryData(null)}
        />
      )}
    </div>
  )
}
