import { _omit } from '@naturalcycles/js-lib'
import { toURL } from '@src/helpers/url'
import { DatoCmsFileField } from 'dato-cms-assets'
import type { DetailedHTMLProps, ImgHTMLAttributes, ReactNode } from 'react'
import { Image, ImagePropTypes, ResponsiveImageType } from 'react-datocms'

type ImageElProps = ImagePropTypes &
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
export interface NcImageProps extends Omit<ImageElProps, 'data'> {
  responsiveImage: ResponsiveImageType
}

/**
 * A wrapper around GatsbyImage that sets values for the `decoding` and `fetchpriority` props'
 * based on the `loading` prop.
 */
export function NcImage({ loading = 'lazy', ...props }: NcImageProps): ReactNode {
  if (
    props.alt === undefined &&
    !props['aria-hidden'] &&
    process.env['GATSBY_ENV'] !== 'production'
  ) {
    console.warn(
      `NcImage: "alt" prop is empty for ${
        props.responsiveImage.src
      }. If this is a decorative image, pass "aria-hidden" prop instead of "alt".`,
    )
  }

  prepareImage(props)

  const imageProps: ImageElProps = {
    priority: loading === 'eager',
    ..._omit(props, ['responsiveImage']),
    // We want to warn on empty alt, but pass '' to the image component and dom to treat it as decorative
    alt: props.alt || '',
    data: props.responsiveImage,
  }

  return <Image {...imageProps} imgStyle={{ objectFit: props.objectFit || 'cover' }} />
}

export function prepareImage<T extends Pick<DatoCmsFileField, 'responsiveImage'>>(image: T): T {
  const asUrl = toURL(image.responsiveImage.src!)
  const searchParams = asUrl?.searchParams
  const auto = searchParams?.get('auto')
  if (!auto) {
    searchParams?.set('auto', 'format')
  } else if (auto && !auto.includes('format')) {
    searchParams?.set('auto', `${auto},format`)
  }

  image.responsiveImage.src = asUrl?.toString()
  return image
}
