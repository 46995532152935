// Brand colors
export const brandPurple = '#72035D'
export const supportPurple = '#560246'
export const brandSalmon = '#fa837f'
export const digitalBlue = '#2674d9'

// Support brand colors
export const lavender = '#e8b3f2'
export const brandPink = '#FFE8E8'
export const lightBlue = 'cce2ff'
export const brandBlue = '#488EE8'

// Neutrals
export const brandGrey = '#f5f3f3'
export const neutral200 = '#f1eaea'
export const neutral300 = '#e2d4d4'

// Greyscale, white, black
export const grey200 = '#a3a3a3'
export const grey400 = '#757575'
export const grey700 = '#5F5F5F'
export const black = '#1a1a1a'

// Sentiment (feedback) colors
export const green500 = '#87B738'
export const red1000 = '#d83030'
export const orange500 = '#ff9000'

// app color palette
export const bleeding = '#D294EF'
export const emotions = '#FFDF5C'
export const pain = '#FF9A75'
export const skin = '#FF928E'
export const sexDrive = '#FD90B4'
export const excluded = '#FF736F'
export const lhTests = '#8DBEFF'
