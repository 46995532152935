import { _findKeyByValue, _objectEntries } from '@naturalcycles/js-lib'
import { useLocation } from '@reach/router'
import { useTranslation } from '@src/hooks/useTranslation'
import { mixpanelService } from '@src/srv/mixpanel.service'
import classNames from 'classnames'
import type { KeyboardEvent, ReactNode } from 'react'
import { useState } from 'react'
import { NcLink } from '../nc-link/NcLink.component'
import styles from './LanguagePicker.module.scss'

export interface LanguagePickerProps {
  sitemap: {
    sitemapLinks: {
      enUs: string
      esUs: string
      ptBr: string
      svSe: string
      enCa: string
    }[]
  }
}

const languages = {
  enUs: 'English',
  esUs: 'Español',
  ptBr: 'Português',
  svSe: 'Svenska',
  enCa: 'English (CA)',
}

export function LanguagePicker({ sitemap: { sitemapLinks } }: LanguagePickerProps): ReactNode {
  const { pathname } = useLocation()
  const translate = useTranslation()
  const [open, toggle] = useState(false)

  function handleOpen(): void {
    toggle(!open)
    mixpanelService.trackClick('LanguagePicker__button')
  }

  const enUsHomePath = pathname === '/'
  const path =
    pathname.endsWith('/') && !enUsHomePath ? pathname.slice(0, pathname.length - 1) : pathname
  const match = sitemapLinks.find(entry => Object.values(entry).includes(path))

  if (!match) {
    return <></>
  }

  const menuClass = classNames(styles.LanguagePicker__menu, {
    [styles.LanguagePicker__openMenu]: open,
  })

  const key = _findKeyByValue(match, path)!

  function handleKeyPress(event: KeyboardEvent): void {
    if (event.code === 'Enter' || event.code === 'Space') {
      toggle(!open)
    }
  }

  return (
    <div className={styles.LanguagePicker} onClick={() => toggle(!open)} uid="language-picker">
      <button
        className={styles.LanguagePicker__button}
        onClick={handleOpen}
        onKeyPress={event => handleKeyPress(event)}
        aria-expanded={open}
        aria-label={translate('lang-picker')}
      >
        <span className={styles.LanguagePicker__buttonText}>{languages[key]}</span>
        <span
          className={`${styles.LanguagePicker__buttonArrow} ${
            open ? styles.LanguagePicker__arrowTransition : ''
          }`}
        />
      </button>

      <ol className={menuClass}>
        {_objectEntries(languages).map(([datoLocale, name]) => {
          const link = match[datoLocale]

          if (!link) {
            return
          }

          return (
            <li key={datoLocale}>
              <NcLink
                uid={'language-option-' + datoLocale}
                onClick={() => toggle(false)}
                to={link}
                className={`${styles.LanguagePicker__link} ${
                  languages[key] === name ? styles.LanguagePicker__selected : ''
                }`}
              >
                {name}
              </NcLink>
            </li>
          )
        })}
      </ol>
    </div>
  )
}
