import { _stringEnumKey } from '@naturalcycles/js-lib'
import { SiteLocale, SupportedLocale } from '@src/cnst/translations.cnst'
import { MainMenuItemRenderer } from '@src/components/menu-item-renderer/MenuItemRenderer.component'
import { languageContext } from '@src/context/language.context'
import { filterMenuItems } from '@src/helpers/filterMenuItems'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { selectGeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import { MobileMenuStatuses, selectMobileMenuStatus } from '@src/store/mobileMenu/mobileMenu.slice'
import { DisplayType, MenuItem, MinorMenuEntry } from '@src/typings/navigation-menu.d'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import styles from './MobileMenu.module.scss'

export interface MobileMenuProps {
  menu: {
    _allNavigationItemsLocales: {
      locale: SiteLocale
      value: MenuItem[]
    }[]
  }
}

export function MobileMenu({ menu: { _allNavigationItemsLocales } }: MobileMenuProps): ReactNode {
  const [isVisible, setIsVisible] = useState(false)
  const [shouldRender, setShouldRender] = useState(false)
  const country = useAppSelector(selectGeolocationCountry)
  const status = useAppSelector(selectMobileMenuStatus)
  const { locale } = useContext(languageContext)
  const menuRef = useRef<HTMLElement>(null)

  const filteredMenuItems: MenuItem[] = filterMenuItems(
    _allNavigationItemsLocales,
    SiteLocale[_stringEnumKey(SupportedLocale, locale)],
    country,
    DisplayType.Mobile,
  )

  const mainMenuItems = filteredMenuItems.filter(
    item => item.componentType.__typename !== 'MinorMenuRecord',
  )
  const minorMenuItems = filteredMenuItems.filter(
    (item): item is MinorMenuEntry => item.componentType.__typename === 'MinorMenuRecord',
  )

  useEffect(() => {
    if (status === MobileMenuStatuses.Open) {
      // Animation triggers for opening
      setShouldRender(true)
      setTimeout(() => setIsVisible(true), 1)

      // Prevent body from scrolling in the background
      document.body.style.overflow = 'hidden'
    } else if (status === MobileMenuStatuses.Closed) {
      // Trigger the closing animation
      setIsVisible(false)
      document.body.style.overflow = ''

      // Wait for animation to complete before unmounting
      // milliseconds must match the animation duration
      const timeout = setTimeout(() => setShouldRender(false), 300)
      return () => clearTimeout(timeout)
    }
  }, [status])

  if (!shouldRender) {
    return null
  }

  return (
    <nav
      id="menu"
      uid="site-mobile-menu"
      ref={menuRef}
      aria-modal="true"
      role="dialog"
      className={classNames(styles.BurgerMenu, {
        [styles.BurgerMenu__openAnimation]: isVisible,
        [styles.BurgerMenu__closeAnimation]: !isVisible,
      })}
    >
      <ul className={classNames(styles.BurgerMenu__container, {})}>
        {mainMenuItems.map(item => (
          <li key={item.id} className={styles.BurgerMenu__item}>
            <MainMenuItemRenderer item={item} />
          </li>
        ))}

        <hr />

        {minorMenuItems.map(item => (
          <li key={item.id} className={styles.BurgerMenu__minorItem}>
            <a uid={item.uid} href={item.componentType.href}>
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )
}
