import { _first, _last } from '@naturalcycles/js-lib'
import { useLocation } from '@reach/router'
import type { PropsWithChildren, ReactNode } from 'react'
import { createContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

export interface GlobalSeoData {
  siteName: string
  titleSuffix: string
  twitterAccount: string
  fallbackSeo: {
    twitterCard: string
  }
}

export interface GlobalSeoProps
  extends PropsWithChildren<{
    _site: {
      globalSeo: GlobalSeoData
    }
  }> {}

export const GlobalSeoContext = createContext<GlobalSeoData>({
  siteName: '',
  titleSuffix: '',
  twitterAccount: '',
  fallbackSeo: {
    twitterCard: '',
  },
})

export function GlobalSeo({ children, _site: { globalSeo } }: GlobalSeoProps): ReactNode {
  const { pathname, host } = useLocation()

  // The pathnames for which we don't want to provide a canonical URL
  // The trailing slash after the domain name is an implied canonical
  // We don't want to index 404 pages
  const noCanonicalPathnames = [
    '/',
    '/404',
    '/404/',
    '/shop/404',
    '/shop/404/',
    '/refer-a-friend',
    '/refer-a-friend/',
    '/join-me',
    '/join-me/',
  ]

  // Clear existing canonical link tags to make sure they dont drag on from previous pages
  function clearLinkTags(): void {
    const existingLinkTags = document.querySelectorAll('link[rel="canonical"]')
    existingLinkTags.forEach(tag => tag.remove())
  }

  useEffect(() => {
    // On first page entry, clear existing canonical link tags and then add the canonical URL if necessary
    clearLinkTags()
    if (!noCanonicalPathnames.includes(pathname)) {
      const canonicalUrl = `https://www.naturalcycles.com${pathname}`
      const linkTag = document.createElement('link')
      linkTag.setAttribute('rel', 'canonical')
      linkTag.setAttribute('href', canonicalUrl)
      document.head.append(linkTag)
    }
  }, [pathname, noCanonicalPathnames])

  const isNoIndex = host && _first(_last(host.split('.')).split(':')) === 'se'

  return (
    <>
      <Helmet>
        <meta property="fb:app_id" content="2012904032120967" />
        <meta property="og:site_name" content={globalSeo.siteName} />
        <meta name="twitter:card" content={globalSeo.fallbackSeo.twitterCard} />
        <meta name="twitter:creator" content={globalSeo.twitterAccount} />
        <meta name="twitter:site" content={globalSeo.twitterAccount} />
        {isNoIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <GlobalSeoContext.Provider value={globalSeo}>{children}</GlobalSeoContext.Provider>
    </>
  )
}
